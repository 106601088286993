const mutations = {
    SET_WORKSPACE: (state, workspace) => {
        state.workspace = workspace
    },
    SET_WORKSPACES_LIST: (state, workspacesList) => {
        state.workspacesList = workspacesList
    },
    SET_WORKSPACES_SESSIONS: (state, workspaceSessions) => {
        state.workspaceSessions = workspaceSessions
    },
    SET_WORKSPACE_LOADING: (state, payload) => {
        state.isWorkspaceLoading = payload;
    },
    SET_WORKSPACES_LIST_LOADING: (state, payload) => {
        state.isWorkspacesListLoading = payload;
    },
    SET_WORKSPACE_SESSIONS_LOADING: (state, payload) => {
        state.isWorkspaceSessionsLoading = payload;
    },
    SET_WORKSPACE_UPDATE_LOADING: (state, payload) => {
        state.isWorkspaceUpdateLoading = payload;
    },
    SET_WORKSPACE_DELETE_LOADING: (state, payload) => {
      state.isDeleteWorkspaceLoading = payload;
    },
    SET_UPDATE_PARTICIPANTS_LOADING: (state, payload) => {
        state.isUpdateParticipantsLoading = payload;
    },
    SET_WORKSPACE_PROMPTS: (state, payload) => {
        state.workspacePrompts = payload;
    },
    PUSH_TO_WORKSPACE_PROMPTS: (state, payload) => {
        state.workspacePrompts.push(payload);
    },
    SET_IS_WORKSPACE_PROMPTS_LOADING: (state, payload) => {
        state.isWorkspacePromptsLoading = payload;
    },
    SET_IS_WORKSPACE_PROMPTS_ACTION_LOADING: (state, payload) => {
        state.isWorkspaceActionLoading = payload;
    },
    SET_SELECTED_WORKSPACE_PROMPT: (state, payload) => {
        state.selectedPrompt = payload;
    },
    SET_WORKSPACE_KNOWLEDGE_FILES: (state, payload) => {
        state.workspaceKnowledgeFiles = payload;
    },
    SET_WORKSPACE_KNOWLEDGE_FILES_LOADING: (state, payload) => {
        state.isWorkspaceKnowledgeFileLoading = payload;
    },
    PUSH_WORKSPACE_FILES: (state, payload) => {
        state.workspaceKnowledgeFiles = [...state.workspaceKnowledgeFiles, ...payload]
    },
    DELETE_WORKSPACE_KNOWLEDGE_FILE: (state, payload) => {
        state.workspaceKnowledgeFiles = state.workspaceKnowledgeFiles.filter((file) => file.id !== payload)
    },
    SET_GENERAL_ACTIONS: (state, payload) => {
        state.generalActions = payload;
    },
    SET_SELECTED_ACTION: (state, payload) => {
        state.selectedAction = payload;
    }
}

export default mutations;